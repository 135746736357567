import React from 'react';
import Section from '../components/Section/Section';


const NotFound = () => (
  <Section title="404">
    Je nám ľúto, ale nenašli sme hľadanú stránku
  </Section>
);

export default NotFound;
